

<script lang="ts" >
import { buildMeta } from "~~/utils/meta-builder";
import { storeRating } from "~~/utils/store-rating";

export default defineComponent({
    setup() {

			const i18n = useI18n()
			const router = useRouter()
			const config = useRuntimeConfig()
			const i18nHead = useLocaleHead({
				addSeoAttributes: true
			})

			const title = i18n.t(`home.meta.title`)
			const description =  i18n.t(`home.meta.description`) 
			const rating = storeRating();

			useHead(buildMeta({
					key:'home', 
					follow: true,
					shareMeta: [
						{ name: 'twitter:site', content: '@SmoxyApp' },
						{ name: 'twitter:app:name:iphone', content: title },
						{ name: 'twitter:app:id:iphone', content: 'id1511487101' },
						
						{ name: 'twitter:app:name:googleplay', content: title },
						{ name: 'twitter:app:id:googleplay', content: 'net.offlinefirst.smoxy' },
								
						{ name: 'twitter:text:title', content: title },
						{ name: 'twitter:text:description', content: description },
						{ name: 'twitter:image', content: 'https://res.cloudinary.com/smoxy/image/upload/v1673951994/web/share-smoxy-app-638x335.jpg' },
						{ name: 'twitter:card', content: 'app' },

						{ name: 'og:title', content: title },
						{ name: 'og:description', content: description },
						{ name: 'og:image', content:  'https://res.cloudinary.com/smoxy/image/upload/v1673952192/web/share-smoxy-app-400x300.jpg' },
						{ name: 'og:image:type', content: 'jpg' },
						{ name: 'og:image:width', content: "400" },
						{ name: 'og:image:height', content: "300"},

						{ name: 'og:url', content: config.public.hostname+router.currentRoute.value.fullPath },
						{ name: 'og:type', content: 'website' },
						{ name: 'og:locale', content: i18nHead.value.htmlAttrs!.lang },	
					],
					script: [
							// Application sturcutred data
							{
									type: 'application/ld-json',
									children: JSON.stringify({
											"@context": "https://schema.org",
											"@type": "SoftwareApplication",
											"name": "Smoxy - Quit Smoking",
											"operatingSystem": "ANDROID",
											"applicationCategory": "HealthApplication",
											"aggregateRating": {
													"@type": "AggregateRating",
													"ratingValue": `${rating.playStore}`,
													"ratingCount": `${rating.playStoreCount}`
											},
											"offers": {
													"@type": "Offer",
													"price": "34.99",
													"priceCurrency": "EUR"
											}
									}),
							},
							{
									type: 'application/ld-json',
									children: JSON.stringify({
											"@context": "https://schema.org",
											"@type": "SoftwareApplication",
											"name": "Smoxy - Quit Smoking",
											"operatingSystem": "IOS",
											"applicationCategory": "HealthApplication",
											"aggregateRating": {
													"@type": "AggregateRating",
													"ratingValue": `${rating.appStore}`,
													"ratingCount": `${rating.appStoreCount}`
											},
											"offers": {
													"@type": "Offer",
													"price": "34.99",
													"priceCurrency": "EUR"
											}
									}),
							},
							//Logo structured data
							/*
							{
									type: 'application/ld-json',
									children: JSON.stringify({
											"@context": "https://schema.org",
											"@type": "Organization",
											"url": "https://www.offlinefirst.net",
											"logo": "https://www.offlinefirst.net/images/logo.png"
									}),
							},
							*/
							

					]
			}))  
    }
});
</script>


<template>
    <div>
     
        <div class="w-full relative">
            <div class="w-full h-screen absolute -z-10">
                <div class="header w-full h-full">
                    <svg class="absolute right-0" width="697" height="660" viewBox="0 0 697 660" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_14818_112467)">
                        <circle cx="522.5" cy="137.5" r="278.5" fill="#FFA5A5" fill-opacity="0.79"/>
                        </g>
                        <defs>
                        <filter id="filter0_f_14818_112467" x="0" y="-385" width="1045" height="1045" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="122" result="effect1_foregroundBlur_14818_112467"/>
                        </filter>
                        </defs>
                    </svg>

                </div>
            </div>

           <Header theme="light" />
            <div class="flex flex-col lg:flex-row max-w-screen-xl px-10 mx-auto items-center mb-2 md:mb-10 overflow-x-hidden md:overflow-x-visible">
                <div class="flex flex-col text-center lg:text-left lg:mb-0">
                    <StoreRating class="hidden lg:flex" />
                    <h1 class="pt-2 text-5xl max-w-xl lg:text-5xl text-[#290B50] font-owners font-bold pb-6" >{{$t('home.teaser.headline')}}</h1>
                    <p class="text-black/75 text-lg font-light mx-auto max-w-xl">{{$t('home.teaser.body')}}</p>    
                    <div class="hidden lg:flex gap-2 pt-10">
                        <PlayStoreButton />
                        <AppStoreButton />
                    </div>
                </div>
                 <AppScreens  />
            </div>
            
           
        </div>  

        <!-- Features -->
        <h2 class="text-4xl md:text-5xl text-center mx-auto text-primary-500 font-gothic pt-10">{{$t('home.features.title')}}</h2>
        
        <FeatureCraving />
        <FeatureSuperPower />
        <FeatureAnalyse />
        <FeatureHealth />
        <FeatureMilestones />
        <FeatureAchievements />
        <FeatureBuddy />
        <FeatureChallenge />
        <FeatureSavings />
        <PrivacySection />
        <Footer />

    </div>
</template>

<style>

.header {
	background: linear-gradient(45deg, #EFEEF6, #EFEEF6, #7E1EF8);
	background-size: 200% 200%;
	animation: gradient 12s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.center-stack {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
 
</style>